import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import ReactMarkdown from "react-markdown";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

import Layout from "../components/Layout";
import DishCard from "../components/DishCard";
import {
  Box,
  Grid,
  GridList,
  GridListTile,
  Tab,
  Tabs,
  Typography,
  Paper,
} from "@material-ui/core";

import "./scss/restaurant.scss";
import ArticleSmallCard from "../components/ArticleSmallCard";

export const query = graphql`
  query RestaurantQuery($id: String) {
    strapiRestaurant(strapiId: { eq: $id }) {
      strapiId
      name
      description
      mainImage {
        publicURL
      }
      knownFor {
        food_item {
          name
        }
        name
        description
        photo {
          publicURL
          childImageSharp {
            resize(cropFocus: CENTER, height: 400, width: 600) {
              src
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
      articles {
        body
        mainImage {
          absolutePath
          childImageSharp {
            resize(cropFocus: CENTER, height: 400, width: 400) {
              src
            }
          }
        }
        title
        slug
      }
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="tab-container"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function Restaurant({ data, width }) {
  const restaurant = data.strapiRestaurant;
  const [value, setValue] = useState(0);

  const getGridListCols = () => {
    if (isWidthUp("xl", width)) {
      return 4;
    }

    if (isWidthUp("lg", width)) {
      return 3;
    }

    if (isWidthUp("md", width)) {
      return 2;
    }

    if (isWidthUp("sm", width)) {
      return 2;
    }

    return 1;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const dishNames = [
    "Pho",
    "Pad Thai",
    "Larb Thai",
    "Bulgogi",
    "Kalbi",
    "Beef Stew",
    "Sashimi",
    "Curry",
    "Wonton Noodle Soup",
  ];

  const descriptions = [
    "Moo shu-wrapped fried Lechon, cucumber, grilled green onions, Mang Tomás-based sauce, side of purple cabbage slaw",
    "Green apple, pickled red onions, green onions, peanuts, fried shallots, herbs, nuoc mam",
    "Sun Ramen Noodles, spicy curry & coconut milk tare, grilled shrimp, fried silken tofu, roasted tomatoes, red cabbage, green onions",
    "Sushi-grade ahi tuna, cucumbers, avocado, green onions, green apples, yellow onions, rice, poke sauce, cashew aioli, pickled radish, furikake, nori, sesame seeds",
  ];

  const dishes = [];

  for (let i = 1; i < 5; i++) {
    dishes.push({
      imageSrc: `https://loremflickr.com/60${i}/40${i}/asianfood`,
      name: dishNames[getRandomInt(9)],
      description: descriptions[getRandomInt(4)],
    });
  }

  const photos = [];

  for (let i = 1; i < 5; i++) {
    photos.push({
      imageSrc: `https://loremflickr.com/60${i}/40${i}/${restaurant.name
        .toLowerCase()
        .replace(/\s/g, "-")}`,
      name: dishNames[getRandomInt(9)],
    });
  }

  return (
    <Layout>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <img
              src={
                restaurant.mainImage
                  ? restaurant.mainImage.publicURL
                  : "https://loremflickr.com/600/400/asianfood"
              }
              alt="[NEED TO CHANGE]"
              className="main-image"
            />
          </Grid>
          <Grid item xs={12}>
            <div className="restaurant-info">
              <Typography component="h1" variant="h4">
                {restaurant.name}
              </Typography>
              <ReactMarkdown source={restaurant.description} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Known For" {...a11yProps(0)} />
                <Tab label="Photos" {...a11yProps(1)} />
                <Tab label="Articles" {...a11yProps(2)} />
              </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
              <GridList cols={getGridListCols()} spacing={10} cellHeight="auto">
                {restaurant.knownFor.map((kF, kfIndex) => {
                  return (
                    <GridListTile key={kfIndex}>
                      <DishCard
                        imageSrc={kF.photo.childImageSharp.resize.src}
                        name={kF.name}
                        description={kF.description}
                      />
                    </GridListTile>
                  );
                })}
              </GridList>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <GridList cols={getGridListCols()} spacing={10} cellHeight="auto">
                {photos.map((p, pIndex) => {
                  return (
                    <GridListTile key={pIndex}>
                      <img
                        src={p.imageSrc}
                        alt={p.name}
                        className="restasurant-photo"
                      />
                    </GridListTile>
                  );
                })}
              </GridList>
            </TabPanel>
            <TabPanel value={value} index={2}>
              {restaurant.articles.length > 0 ? (
                <GridList
                  cols={getGridListCols()}
                  spacing={10}
                  cellHeight="auto"
                >
                  {restaurant.articles.map((a, aIndex) => {
                    return (
                      <GridListTile key={aIndex}>
                        <ArticleSmallCard
                          slug={a.slug}
                          title={a.title}
                          imageSrc={a.mainImage.childImageSharp.resize.src}
                        />
                      </GridListTile>
                    );
                  })}
                </GridList>
              ) : (
                <p>No articles yet</p>
              )}
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}

Restaurant.propTypes = {
  data: PropTypes.object,
};

export default withWidth()(Restaurant);
